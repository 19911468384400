<template>
  <div>
    <heads :msg="title_msg" :states="1" :address_management2="1"></heads>
    <div v-for="(item,index) in list" :key="index" class="main" @click="selectAddress(item)">
      <div class="left_box">
        <p :class="item.is_default?'icon_select':'icon_defult'">{{ get_sliceOne(item.receiver) }}</p>
      </div>
      <div class="right_box">
        <div class="right_top">
          <p class="title">{{ item.receiver }}</p>
          <p class="tel">{{ item.mobile }}</p>
          <span
            :class="item.is_default?'btn_select':'btn_defult'"
            @click.stop="defaults(item.id)"
          >{{ item.is_default?'默认地址':'设为默认' }}</span>
        </div>
        <div class="right_bottom">
          <div class="bottom_left">
            <p>{{ `${item.address_json.province} ${item.address_json.city} ${item.address_json.county} ${item.detail_address}` }}</p>
          </div>
          <div class="bottom_right">
            <span @click.stop="edit_address(item)">编辑</span>
            <span @click.stop="del(item.id)">删除</span>
          </div>
        </div>
      </div>
    </div>
    <div class="div_empty"></div>
    <van-button round type="danger" class="btn_submit" block @click="go_add_addressJd()">+ 添加收货地址</van-button>

    <!-- <div v-for="(item, index) in list" :key="index" class="main">
      <div class="main_left">
        <div v-if="item.is_default" class="heads_tit heads_tit1">
          <p class="heads_tit_name">{{ get_sliceOne(item.receiver) }}</p>
        </div>
        <div v-else class="heads_tit">
          <p class="heads_tit_name">{{ get_sliceOne(item.receiver) }}</p>
        </div>
      </div>
      <div class="main_center">
        <p class="names">{{ item.receiver }}<span>{{ item.mobile }}</span></p>

        <span
          v-if="item.is_default"
          class="defaults defaults1"
          @click="defaults(item.id)"
        >
          {{ item.is_default?'默认地址':'设为默认' }}
        </span>
        <span
          v-else
          class="defaults"
          @click="defaults(item.id)"
        >
          {{ item.is_default?'默认地址':'设为默认' }}
        </span>

        <p class="addresss">{{ `${item.address_json.province}    ${item.address_json.city}    ${item.address_json.county}   ${item.detail_address}` }}</p>
      </div>
      <div class="main_right">
        <span @click="edit_address(item)">编辑</span>
        <span @click="del(item.id)">删除</span>
      </div>
    </div>-->
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { getAddressList, set_jdDefault_address, deleteAddress } from '@/api/my_user'
import { Toast } from 'vant'
export default {
  components: {
    heads
  },
  data() {
    return {
      backs: 0,
      title_msg: '地址管理',
      list: [],
      a: '',
      b: 'asasass'
    }
  },
  mounted() {
    this.addressList()
  },
  methods: {
    // 截取姓名第一位
    get_sliceOne(data) {
      var new_data = data.slice(0, 1)
      return new_data
    },
    // 获取地址列表
    addressList() {
      getAddressList().then(res => {
        this.list = res.result.list
      })
    },
    // 设为默认地址
    defaults(ids) {
      const params = {
        id: ids,
        is_default: 1
      }
      set_jdDefault_address(params).then(res => {
        this.addressList()
        if (res.errNo === 0) {
          Toast('设置成功')
          // this.$router.go(-1)
        }
      })
    },
    // 选地址
    selectAddress(item) {
      // console.log(item);
      if (this.$route.query.type == 'exchangeCode') {
        console.log(item)
        this.$router.push({
          path: '/ExchangeCode',
          query: {
            AddressInfo: JSON.stringify(item),
            codeadd: this.$route.query.code,
            pct_name: this.$route.query.pct_name,
            thumb: this.$route.query.thumb
          }
        })
        return false
      }
      sessionStorage.setItem('selectAddJD', JSON.stringify(item))
      this.$router.go(-1)
    },
    go_add_addressJd() {
      if (this.list.length >= 5) {
        this.$toast('最多添加五个收货地址')
      } else {
        if (sessionStorage.getItem('clickIndex') === null) {
          this.$router.push({ path: '/addAddress' })
          sessionStorage.setItem('clickIndex', 1)
        } else {
          this.$router.replace({ path: '/addAddress' })
        }
      }
    },
    // 删除地址
    del(ids) {
      const params = {
        id: ids,
        status: 0
      }
      deleteAddress(params).then(res => {
        this.addressList()
      })
    },
    // // 编辑
    edit_address(item) {
      if (sessionStorage.getItem('clickIndex') === null) {
        this.$router.push({
          path: 'addAddress',
          query: {
            one_id: item.id
          }
        })
        sessionStorage.setItem('clickIndex', 1)
      } else {
        this.$router.replace({
        // path: '/index/express/edit',
          path: 'addAddress',
          query: {
            one_id: item.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 170px;
  border-bottom: 4px solid #e9e9e9;
  display: flex;
  justify-content: start;
}
.left_box {
  padding: 0 30px 0 20px;
}
.left_box .icon_select {
  background-color: #ee2e2e;
  text-align: center;
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: #ffffff;
  border-radius: 50%;
  margin-top: 53px;
}
.left_box .icon_defult {
  background-color: #b8b8b8;
  text-align: center;
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: #ffffff;
  border-radius: 50%;
  margin-top: 53px;
}

.right_box {
  flex: 1;
}
.right_top {
  height: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.title {
  padding: 0 10px 0 0;
  font-size: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // width: 260px;
  max-width: 260px;
  line-height: 48px;
  color: #333333;
  font-weight: 600;
}
.tel {
  background-color: white;
  font-size: 30px;
  margin-left: 10px;
  // width: 200px;
  min-width: 200px;
  line-height: 48px;
  color: #333333;
  font-weight: 600;
}
.btn_defult {
  color: #eb3f3d;
  border: 3px solid #eb3f3d;
  border-radius: 30px;
  padding: 3px 10px;
  margin-left: 20px;
  display: inline-block;
  font-size: 24px;
  // width: 100px;
  // height: 30px;
  // line-height: 30px;
  text-align: center;
}
.btn_select {
  background: #eb3f3d;
  color: #ffffff;
  border: 3px solid #eb3f3d;
  border-radius: 30px;
  padding: 3px 10px;
  margin-left: 20px;
  display: inline-block;
  font-size: 24px;
  // width: 100px;
  // height: 30px;
  // line-height: 30px;
  text-align: center;
}

.right_bottom {
  height: 50%;
  display: flex;
  justify-content: space-between;
}
.bottom_left {
  width: calc(100% - 104px);
  p {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    // margin-top: 8px;
    font-size: 24px;
    line-height: 32px;
    color: #333333;
    letter-spacing: 0;
  }
}
.bottom_right {
  padding: 0 20px 0 20px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #979797;
  }
}
.div_empty{
  height: 200px;
}
.btn_submit{
  width: 80%;
  background: #ff272c;
  position: fixed;
  left: 50%;
  margin-left: -40%;
  margin-bottom: 40px;
  bottom:0;
}
// .defaults {
//   color: #eb3f3d;
//   border: 3px solid #eb3f3d;
//   border-radius: 30px;
//   // transform: scale(0.5);
//   padding: 3px 10px;
//   font-size: 8px;
//   transform: scale(0.95);
//   margin-left: 20px;
//   display: inline-block;
// }
// .defaults1{
//     background:#eb3f3d ;
//     color: #ffffff;
//   border: 3px solid #eb3f3d;
//   border-radius: 30px;
//   // transform: scale(0.5);
//   padding: 3px 10px;
//   font-size: 8px;
//   transform: scale(0.95);
//   margin-left: 20px;
//   display: inline-block;
// }
// .addresss {
//   padding-top: 10px !important;
//   font-size: 25px !important;
//   position: absolute;
//   bottom: 20px;
//   left: 0;
// }
// .main {
//   width: 100%;
//   height: 167px;
//   border-bottom: 4px solid #e9e9e9;
//   position: relative;
// }
// .main_left {
//   height: 167px;
//   width: 125px;
//   float: left;
// }
// .main_center {
//   position: relative;
//   height: 167px;
//   width: 500px;
//   float: left;
//   p {
//     display: inline-block;
//     font-size: 30px;
//     padding-top: 39px;
//     span {
//       color: #979797;
//       padding-left: 25px;
//     }
//   }
// }
// // .main_right {
// //   height: 167px;
// //   width: 158px;
// //   float: right;
// //   position: relative;
// // }
// .main_right span:nth-child(1) {
//   color: #979797;
//   position: absolute;
//   bottom: 22px;
//   right: 100px;
// }
// .main_right span:nth-child(2) {
//   color: #979797;
//   position: absolute;
//   bottom: 22px;
//   right: 30px;
// }
// .heads_tit {
//   width: 64px;
//   height: 64px;
//   border-radius: 50%;
//   background: #b8b8b8;
//   margin: 47px 0 0 29px;
// }
// .heads_tit1 {
//   width: 64px;
//   height: 64px;
//   border-radius: 50%;
//   background: #eb3d34;
//   margin: 47px 0 0 29px;
// }
// .heads_tit_name {
//   color: #ffffff;
//   text-align: center;
//   line-height: 64px;
// }
// .heads_tit_name1 {
//   color: #ffffff;
//   text-align: center;
//   line-height: 64px;
// }
</style>
